import {format} from 'date-fns'
import {merge} from "lodash-es";

const CSRF_TOKEN_KEY = 'csrftoken';

export function get_type(value) {
  return Object.prototype.toString.call(value).slice(8, -1)
}

export function dict_to_search(data = {}) {
  let ret = new URLSearchParams(data);
  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      ret.delete(key);
      for (const subval of value) {
        ret.append(key, subval)
      }
    }
    if (get_type(value) == 'Date') {
      ret.set(key, date_format(value))
    }

  }
  return ret
}

export function date_format(value, default_value = 'Set value') {
  return value ? format(new Date(value), 'yyyy-LL-dd') : default_value
}

export function capitalyze(v) {
  let ret = `${v}`
  return ret[0].toUpperCase() + ret.slice(1).toLowerCase()
}

export function to_query(data) {
  let q = merge({}, data);
  if (q.start) {
    q.start = date_format(q.start);
  } else {
    delete q.start
  }
  if (q.end !== null) {
    q.end = date_format(q.end);
  }
  for (const [key, value] of Object.entries(data)) {
    if (value === null) {
      delete q[key]
    }
  }
  return q
}

export function getCookie(name = CSRF_TOKEN_KEY) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function getName(obj, key = 'name') {
  if (!obj) return '';
  let name = obj[key]
  if (obj.deleted && !name.endsWith(' *')) {
    name += ' *'
  }
  return name
}

export function genColor() {
  return '#' + ((1 << 24) * (Math.random() + 1) | 0).toString(16).substr(1)
}

export function getLCName(getters, obj) {
  let prefix = '';
  if (['line', 'cell'].indexOf(obj.type) > -1) {
    if (getters.departament.length) {
      let r = getters.departament_as_uuid[obj.departament_uuid] || null;
      if (r) {
        prefix = `${r._name}: `;
      }
    }
  }
  return `${prefix}${getName(obj)}`
}
