<template>
  <transition name="fade" mode="out-in">
    <router-view />
  </transition>
</template>

<script>
import { throttle } from "lodash-es";

const event_name = "mousemove";
export default {
  provide() {
    return {
      _add_event_listner: this.add_event_listner,
      _remove_event_listner: this.remove_event_listner,
    };
  },
  mounted() {
    this.$store.dispatch("check_login").then((resp) => {
      if (resp) {
        this.$store.dispatch("load_data");
        this.add_event_listner();
      } else {
        if (this.$route.name != "Home") this.$router.push({ name: "Home" });
      }
    });
    this.$watch(
      () => this.$store.getters.has_auth,
      (to, from) => {
        if (from && !to) {
          this.remove_event_listner();
        }
      }
    );
  },
  beforeDestroy() {
    this.remove_event_listner();
  },
  data() {
    return {
      last_catch: Date.now(),
      db_method: null,
    };
  },
  methods: {
    add_event_listner() {
      if (this.db_method === null) {
        this.last_catch = Date.now();
        if (this.$store.getters.use_background_check) {
          console.log(
            "hc:ev_handler use debounce",
            this.$store.getters._timeout_debounce
          );
          const event_handler = throttle(
            this.ev_handler,
            this.$store.getters._timeout_debounce
          );
          this.db_method = event_handler;
          window.addEventListener(event_name, this.db_method);
        }
      }
    },
    remove_event_listner() {
      if (this.db_method !== null) {
        window.removeEventListener(event_name, this.db_method);
        this.db_method = null;
      }
    },
    ev_handler() {
      let current_dt = Date.now();
      const dt_diff = current_dt - this.last_catch;
      const timeout_diff =
        this.$store.getters._timeout_timeout -
        this.$store.getters._timeout_debounce;
      console.log("hc:ev_handler", current_dt, "diff", dt_diff);
      if (isNaN(timeout_diff)) {
        return;
      }
      if (dt_diff < timeout_diff) {
        this.last_catch = current_dt;
        console.log("hc:ev_handler update_last_catch");
      } else {
        console.log("hc:ev_handler logout??");
        if (this.$store.getters.has_auth)
          this.$store.dispatch("logout").then(() => {
            this.last_catch = current_dt;
            if (this.$route.name !== "Home")
              this.$router.push({ name: "Home" });
          });
      }
    },
  },
};
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";
@import "~bootstrap-vue/src/icons.scss";
@import "./static/scss/styles.scss";

body {
  margin-top: 0.5em;

  .b-calendar-grid-help {
    display: none !important;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>

